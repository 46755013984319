<template>
  <div>
    <b-form-group
      id="lemma-group"
      label="Lemma"
      label-for="lemma"
      invalid-feedback="Lemma is required"
      :state="lemmaState"
    >
      <LemmaSelect @select="selectLemma" :isRequired="isLemmaRequired" :lemma="this.model.occurrenceLemma.lemma" />
    </b-form-group>

    <b-form-group
      id="public-comment-sense-group"
      label="Public Comment Sense"
      label-for="public-comment-sense"
    >
      <b-form-textarea
        id="public-comment-sense"
        placeholder="Public Comment Sense"
        v-model="model.publicCommentSense"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="public-comment-spelling-group"
      label="Public Comment Spelling"
      label-for="public-comment-spelling"
    >
      <b-form-textarea
        id="public-comment-spelling"
        placeholder="Public Comment Spelling"
        v-model="model.publicCommentSpelling"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="comment-group"
      label="Comment"
      label-for="comment"
    >
      <b-form-textarea
        id="comment"
        placeholder="Comment"
        v-model="model.comment"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <h6 class="with-line">External Evidence</h6>
    <!-- external evidence -->
    <b-row>
      <b-col>
        <b-form-group
          id="editionSigleLegam-group"
          label="Edition Sigle Legam"
          label-for="editionSigleLegam"
          invalid-feedback="Edition and/or Manuscript is required"
          :state="editionState"
        >
          <EditionSelect @select="selectEdition" :edition="this.model.externalEvidence.edition" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="manuscriptCode-group"
          label="Manuscript Code"
          label-for="manuscriptCode"
          invalid-feedback="Manuscript and/or Edition is required"
          :state="manuscriptState"
        >
          <ManuscriptSelect @select="selectManuscript" :manuscript="this.model.externalEvidence.manuscript" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          id="study-group"
          label="Study"
          label-for="study"
        >
          <StudySelect @select="selectStudy" :study="this.model.externalEvidence.study" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="location-group"
          label="Location"
          label-for="location"
        >
          <LocationSelect @select="selectLocation" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      id="context-group"
      label="Context"
      label-for="context"
    >
      <b-form-textarea
        id="context"
        placeholder="Context"
        v-model="model.externalEvidence.context"
        rows="3"
        max-rows="6"
      ></b-form-textarea>
    </b-form-group>

    <b-form-group
      id="position-group"
      label="Position"
      label-for="position"
    >
      <b-form-input
        id="position"
        v-model="model.externalEvidence.position"
        placeholder="Position"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      id="surfaceForm-group"
      label="Surface Form"
      label-for="surfaceForm"
    >
      <b-form-input
        id="surfaceForm"
        v-model="model.externalEvidence.surfaceForm"
        placeholder="Surface Form"
      ></b-form-input>
    </b-form-group>

    <!-- <b-form-group
      id="annotatedForm-group"
      label="Annotated Form"
      label-for="annotatedForm"
    >
      <b-form-input
        id="annotatedForm"
        v-model="model.externalEvidence.annotatedForm"
        placeholder="Annotated Form"
      ></b-form-input>
    </b-form-group> -->

    <b-form-group
      id="partOfSpeech-group"
      label="Part of Speech"
      label-for="partOfSpeech"
    >
      <b-form-input
        id="partOfSpeech"
        v-model="model.externalEvidence.partOfSpeech"
        placeholder="Part of Speech"
      ></b-form-input>
    </b-form-group>

    <b-row>
      <b-col>
        <b-form-group
          id="dateSpecManuscript-group"
          label="Date Spec Manuscript"
          label-for="dateSpecManuscript"
        >
          <b-form-input
            id="dateSpecManuscript"
            v-model="model.externalEvidence.dateSpecManuscript"
            placeholder="Date Spec Manuscript"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="dateNumManuscript-group"
          label="Date Num Manuscript"
          label-for="dateNumManuscript"
        >
          <b-form-input
            id="dateNumManuscript"
            type="date"
            v-model="model.externalEvidence.dateNumManuscript"
            placeholder="Date Num Manuscript"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          id="dateSpecText-group"
          label="Date Spec Text"
          label-for="dateSpecText"
        >
          <b-form-input
            id="dateSpecText"
            v-model="model.externalEvidence.dateSpecText"
            placeholder="Date Spec Text"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          id="dateNumText-group"
          label="Date Num Text"
          label-for="dateNumText"
        >
          <b-form-input
            id="dateNumText"
            type="date"
            v-model="model.externalEvidence.dateNumText"
            placeholder="Date Num Text"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import HelpStore from '@/store/helpstore.js'
  import EditionSelect from '@/components/Bibliography/EditionSelect.vue'
  import ManuscriptSelect from '@/components/Bibliography/ManuscriptSelect.vue'
  import LemmaSelect from '@/components/Lemma/LemmaSelect.vue'
  import LocationSelect from '@/components/Main/LocationSelect.vue'
  import StudySelect from '@/components/Bibliography/StudySelect.vue'

  export default {
    name: 'OccurrenceNew',
    props: ['isLemmaRequired', 'type', 'occurrence'],
    data(){
      let baseNew = {
        id: null,
        publicCommentSense: '',
        publicCommentSpelling: '',
        comment: '',
        _occurrenceType: this.type,
        lemmas: [],
        occurrenceLemma: {
          lemmaId: null
        },
        externalEvidence: {
          manuscriptCode: null,
          editionId: null,
          studyId: null,
          locationId: null,
          surfaceForm: '',
          dateSpecManuscript: '',
          dateNumManuscript: null,
          dateSpecText: '',
          dateNumText: null,
          context: '',
          partOfSpeech: '',
          position: ''
        }
      }
      if (this.occurrence) {
        baseNew = this.occurrence
        baseNew.id = null
      }
      if (!baseNew.occurrenceLemma){
        baseNew.occurrenceLemma = {
          lemmaId: null
        }
      }
      return {
        // TODO: fetch from API
        model: baseNew,
        lemmaState: null,
        editionState: null,
        manuscriptState: null
      }
    },
    mounted(){
      HelpStore.item = this.model
    },
    components: {
      EditionSelect,
      ManuscriptSelect,
      LemmaSelect,
      LocationSelect,
      StudySelect
    },
    updated(){
      HelpStore.item = this.model
    },
    methods: {
      selectEdition(edition){
        this.model.externalEvidence.editionId = null
        this.model.externalEvidence.edition = null
        if (edition) {
          this.model.externalEvidence.editionId = edition.id
          this.model.externalEvidence.edition = edition
        }
        HelpStore.item = this.model
      },
      selectStudy(study){
        this.model.externalEvidence.studyId = null
        this.model.externalEvidence.study = null
        if (study) {
          this.model.externalEvidence.studyId = study.id
          this.model.externalEvidence.study = study
        }
        HelpStore.item = this.model
      },
      selectLocation(location){
        this.model.externalEvidence.locationId = null
        this.model.externalEvidence.location = null
        if(location){
          this.model.externalEvidence.locationId = location.id
          this.model.externalEvidence.location = location
        }
        HelpStore.item = this.model
      },
      selectManuscript(manuscript){
        this.model.externalEvidence.manuscriptId = null
        this.model.externalEvidence.manuscript = null
        if (manuscript){
          this.model.externalEvidence.manuscriptId = manuscript.id
          this.model.externalEvidence.manuscript = manuscript
        }
        HelpStore.item = this.model
      },
      selectLemma(lemma){
        let lemmaOccurrence = null
        if (lemma){
          if (this.type == 'containeroccurrence'){
            lemmaOccurrence = {
              lemmaId: lemma.id,
              type: this.type,
              occurrenceId: null,
              containeroccurrenceId: this.model.id,
            }
          }
          else {
            lemmaOccurrence = {
              lemmaId: lemma.id,
              type: this.type,
              occurrenceId: this.model.id,
              containeroccurrenceId: null
            }
          }
        }
        this.model.occurrenceLemma = lemmaOccurrence
        HelpStore.item = this.model
      },
      validate(){
        this.lemmaState = (this.isLemmaRequired != true || this.model.occurrenceLemma.lemmaId != null)
        this.editionState = (this.model.externalEvidence.manuscriptId != null || this.model.externalEvidence.editionId != null)
        this.manuscriptState = this.editionState
        return this.lemmaState && (this.editionState || this.manuscriptState)
      }
    }
  }
</script>
